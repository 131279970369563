export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'My Orders',
    route: 'orders',
    icon: 'FileTextIcon',
  },
  {
    title: 'Hotels',
    route: 'admin-hotels-list',
    icon: 'HomeIcon',
    resource: 'admin',
    action: 'write',
  },
  {
    title: 'Restaurants',
    route: 'admin-restaurants-list',
    icon: 'CoffeeIcon',
    resource: 'admin',
    action: 'write',
  },
  {
    title: 'Convenience',
    route: { path: '/admin/restaurants/view?id=60fb9744805e3610e4374ec2' },
    icon: 'CoffeeIcon',
    resource: 'admin',
    action: 'write',
  },
]
