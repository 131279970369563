<template>
  <div
    class="navbar-container d-flex content align-items-center mx-1"
    style="min-height: 50px"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li v-if="$route.meta.showMenu" class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <img
            :src="require('@/assets/images/icons/menu.svg')"
            alt="menu"
            width="21"
            height="21"
          />
        </b-link>
      </li>
      <li v-if="$route.meta.backButton" class="nav-item">
        <feather-icon
          class="text-dark cursor-pointer"
          icon="ArrowLeftCircleIcon"
          size="23"
          @click="$router.back()"
        />
      </li>
      <li v-else class="ml-2" />
    </ul>

    <!-- Left Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <hotel-dropdown v-if="!$route.path.includes('/admin/')" />
    </b-navbar-nav>
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-lg-none d-none" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--      <b-nav-item-dropdown-->
      <!--        right-->
      <!--        toggle-class="d-flex align-items-center dropdown-user-link"-->
      <!--        class="dropdown-user"-->
      <!--      >-->
      <!--        <template #button-content>-->
      <!--          <div class="d-sm-flex d-none user-nav">-->
      <!--            <p class="user-name font-weight-bolder mb-0">-->
      <!--              John Doe-->
      <!--            </p>-->
      <!--            <span class="user-status">Admin</span>-->
      <!--          </div>-->
      <!--          <b-avatar-->
      <!--            size="40"-->
      <!--            variant="light-primary"-->
      <!--            badge-->
      <!--            :src="require('@/assets/images/avatars/13-small.png')"-->
      <!--            class="badge-minimal"-->
      <!--            badge-variant="success"-->
      <!--          />-->
      <!--        </template>-->

      <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
      <!--          <feather-icon-->
      <!--            size="16"-->
      <!--            icon="UserIcon"-->
      <!--            class="mr-50"-->
      <!--          />-->
      <!--          <span>Profile</span>-->
      <!--        </b-dropdown-item>-->

      <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
      <!--          <feather-icon-->
      <!--            size="16"-->
      <!--            icon="MailIcon"-->
      <!--            class="mr-50"-->
      <!--          />-->
      <!--          <span>Inbox</span>-->
      <!--        </b-dropdown-item>-->

      <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
      <!--          <feather-icon-->
      <!--            size="16"-->
      <!--            icon="CheckSquareIcon"-->
      <!--            class="mr-50"-->
      <!--          />-->
      <!--          <span>Task</span>-->
      <!--        </b-dropdown-item>-->

      <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
      <!--          <feather-icon-->
      <!--            size="16"-->
      <!--            icon="MessageSquareIcon"-->
      <!--            class="mr-50"-->
      <!--          />-->
      <!--          <span>Chat</span>-->
      <!--        </b-dropdown-item>-->

      <!--        <b-dropdown-divider />-->

      <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
      <!--          <feather-icon-->
      <!--            size="16"-->
      <!--            icon="LogOutIcon"-->
      <!--            class="mr-50"-->
      <!--          />-->
      <!--          <span>Logout</span>-->
      <!--        </b-dropdown-item>-->
      <!--      </b-nav-item-dropdown>-->
      <cart-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import CartDropdown from '@/components/CartDropdown.vue'
import HotelDropdown from '@/components/HotelDropdown.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapState } from 'vuex'

export default {
  components: {
    BLink,
    BNavbarNav,
    CartDropdown,
    HotelDropdown,
    // BNavItemDropdown,
    // BDropdownItem,
    // BDropdownDivider,
    // BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      cart: state => state.cart.added,
      cartCount: state =>
        state.cart.added.reduce((item, current) => ({
          quantity: item.quantity + current.quantity,
        })).quantity,
    }),
  },
}
</script>
