<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25 custom-dropdown-menu"
    menu-class="dropdown-menu-media"
    right
    style=""
    @show="fetchItems"
    @hide="dropdownHide"
  >
    <template #button-content>
      <div>
        <div v-if="$route.meta.showCart && cart.length" class="fadein">
          <feather-icon
            class=" text-dark"
            style="transition: all 1s"
            :badge="cartCount"
            badge-classes="badge-dark"
            icon="ShoppingCartIcon"
            size="21"
          />
        </div>
        <div v-else class="mr-2" />
      </div>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ cartRestaurant.name ? cartRestaurant.name : 'My Cart' }}
        </h4>
        <b-badge pill variant="light-primary"> {{ cartCount }} Items </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <div v-if="cart.length" style="max-height: 60vh; overflow-y: auto">
      <div
        v-for="(item, index) in cart"
        :key="item.name + index"
        class="d-flex p-1"
        style="border-top: 1px solid #cccccc55;max-height: 100px"
      >
        <b-img
          :src="
            'https://omnimenuio.imgix.net/' +
              item.url +
              '?ixlib=vue-2.8.4&auto=format&fit=crop&crop=entropy&h=300&w=300&dpr=1&q=75'
          "
          :alt="item.name"
          rounded
          width="62px"
        />
        <div class="d-flex justify-content-between flex-grow-1">
          <div class="bg-white text-dark font-weight-bolder" style="padding-left: 3%;">
            {{ item.name }}
          </div>
          <div class="d-flex justify-content-around">
            <div class="d-flex flex-column align-items-center justify-content-around h-100">
              <h5 class="mx-1">${{ item.totalPrice }}</h5>
            </div>
            <b-form-spinbutton
              :value="item.quantity"
              min="0"
              size="sm"
              vertical
              @change="updateItemQuantity(item.cartItemId, $event)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Cart Footer -->
    <li v-if="cart.length" class="dropdown-menu-footer">
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Total:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">${{ totalAmount }}</h6>
      </div>
      <b-dropdown-item class="custom-dropdown-item">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="dark"
          style="border-radius: 0"
          block
          :to="{ name: 'order-summary' }"
        >
          Order Summary
        </b-button>
      </b-dropdown-item>
    </li>

    <p v-if="!cart.length" class="m-0 p-1 text-center text-dark font-weight-bold">
      Your cart is empty
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BImg,
  BFormSpinbutton,
  BButton,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BBadge,
    BImg,
    BFormSpinbutton,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      cart: state => state.cart.added,
      cartRestaurant: state => state.restaurant.cartRestaurant,
      cartCount: state =>
        state.cart.added.reduce(
          (item, current) => ({ quantity: item.quantity + current.quantity }),
          { quantity: 0 }
        ).quantity,
    }),
    totalAmount() {
      let total = 0
      this.cart.forEach(i => {
        total += i.totalPrice
      })
      return total
    },
  },
  methods: {
    fetchItems() {
      document.body.classList.add('modal-open')
      // this.$store.dispatch('app-ecommerce/fetchCartProducts')
      //   .then(response => {
      //     this.items = response.data.products
      //   })
    },
    dropdownHide() {
      document.body.classList.remove('modal-open')
    },
    updateItemQuantity(cartItemId, quantity) {
      if (quantity === 0) {
        this.$store.dispatch('cart/removeItemFromCart', cartItemId)
      } else this.$store.dispatch('cart/updateCartItemQuantity', { cartItemId, quantity })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
<style>
.custom-dropdown-item > a {
  padding: 0 !important;
}
.custom-dropdown-menu ul.dropdown-menu {
  max-height: 85vh;
  overflow-y: auto !important;
}
</style>
