<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25 custom-dropdown-menu"
    menu-class="dropdown-menu-media"
    style=""
  >
    <template #button-content>
      <div>
        <div
          v-if="true || $route.meta.showDeliveringTo !== true"
          class="position-relative"
          @click.stop="$router.push({ path: hotel.path })"
        >
          <cld-image
            v-if="hotel.logo"
            :publicId="hotel.logo"
            style="height: 35px;"
            @click.stop="$router.push({ path: hotel.path })"
          >
            <cld-transformation height="50" crop="fill" />
          </cld-image>
          <b-avatar
            v-else-if="hotel.name"
            :src="hotel.avatar"
            :text="avatarText(hotel.name)"
            variant="light-dark"
            size="104px"
            rounded
            style="height:35px!important;"
          />
        </div>
        <div v-else class="d-flex align-items-center fadein" style="transition: all 1s">
          <div class="position-relative" @click.stop="$router.push({ path: hotel.path })">
            <cld-image
              v-if="hotel.logo"
              :publicId="hotel.logo"
              style="height: 30px;"
              @click.stop="$router.push({ path: hotel.path })"
            >
              <cld-transformation height="50" crop="fill" />
            </cld-image>
            <b-avatar
              v-else-if="hotel.name"
              :src="hotel.avatar"
              :text="avatarText(hotel.name)"
              variant="light-dark"
              size="104px"
              rounded
              style="height: 35px!important;"
            />
          </div>
          <div class="ml-1">
            <div class="text-center text-dark font-weight-bold font-small-2">
              delivering to:
            </div>
            <div class="d-flex justify-content-start">
              <div
                class="text-dark font-weight-bolder font-small-4"
                style="max-width: 20vw; overflow: hidden; text-overflow: ellipsis"
              >
                {{ hotel.name || 'No Hotel Selected' }}
              </div>
              <feather-icon class="text-dark" icon="ChevronDownIcon" size="21" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="">
      <div class="h5 m-1 text-dark">
        <span class="font-weight-bolder text-dark">Name: </span> {{ hotel.name }}
      </div>
      <div class="h5 m-1 text-dark">
        <span class="font-weight-bolder text-dark">Address: </span
        >{{
          (hotel.address || {}).printable ||
            `No address found for
         this
        hotel`
        }}
      </div>
      <div class="h5 m-1 text-dark">
        <span class="font-weight-bolder">Room: #</span
        >{{ hotel.roomNumber || 'No room is selected' }}
      </div>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BAvatar } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    ...mapState({
      hotel: state => state.hotel.hotel || {},
    }),
  },
  methods: {
    avatarText,
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
